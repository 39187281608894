import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AddNewDataMenu from '../components/bodyData/AddNewDataMenu/AddNewDataMenu';
import DashboardCards from '../components/cards/DashboardCards/DashboardCards';
import GeneralLoading from '../components/GeneralLoading/GeneralLoading';
import { useAppSelector } from '../redux/hooks';
import { setCurrentPagesName } from '../redux/slices/currentPageSlice';
import dateFormat from '../utils/BodyData/dateFormat';
import getLatest from '../utils/BodyData/getLatest';
import capitalizeFirstLetter from '../utils/formatting/capitalizeFirstLetter';
import './Dashboard.scss';

export default function Dashboard(): JSX.Element {
  const bodyData = useAppSelector((state) => state.bodyData.value);
  const userInfo = useAppSelector((state) => state.userInfo.value);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentPagesName('Dashboard'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!bodyData && typeof bodyData === 'boolean') return <GeneralLoading text='Dashboard' />;
  if (bodyData === null)
    return (
      <Card className='dashboardNoDataYet'>
        <Typography variant='h5'>
          Hi, {capitalizeFirstLetter(userInfo.first)}! We dont have any data from you yet! Click on
          the + icon to add something
        </Typography>
      </Card>
    );
  const data = [
    {
      title: 'Weight',
      color: 'red',
      value: getLatest(bodyData.weight),
    },
    {
      title: 'Fat Percentage',
      color: 'green',
      value: getLatest(bodyData.fat),
    },
    {
      title: 'Info',
      color: 'blue',
    },
  ];

  return (
    <Box className='dashboard'>
      <Typography className='dashboardTitle' variant='h4'>
        Hello, {capitalizeFirstLetter(userInfo.first)}
      </Typography>
      <div className='dashboardOverview'>
        {data.map((e) => {
          if (e.value) {
            return (
              <DashboardCards key={e.color} color={e.color}>
                <Typography variant='h5'>{e.title}</Typography>
                <Typography variant='body1'>
                  Last value: <b>{e.value.value}</b>
                </Typography>
                <Typography variant='body2'>
                  Last updated: <b>{dateFormat(new Date(e.value.timestamp))}</b>
                </Typography>
              </DashboardCards>
            );
          } else {
            return null;
          }
        })}
        <div className='addNewDataMenuMobileWrapper'>
          <AddNewDataMenu type='mobile' />
        </div>
      </div>
    </Box>
  );
}
