import { useMutation } from '@apollo/client';
import { Stepper, Step, StepLabel, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import REGISTER_MUTATION from '../../../api/mutations/REGISTER_MUTATION';
import GeneralLoading from '../../../components/GeneralLoading/GeneralLoading';
import { setCurrentPagesName } from '../../../redux/slices/currentPageSlice';
import { setloggedIn } from '../../../redux/slices/loggedInSlice';
import './Register.scss';
import RegisterStart from './steps/RegisterStart';
import RegisterStep1 from './steps/RegisterStep1';
import RegisterStep2 from './steps/RegisterStep2';
import RegisterStep3 from './steps/RegisterStep3';

export default function Register() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPagesName('Register'));
  });

  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const loginMutationString = REGISTER_MUTATION(email, password, { first, last });

  const [mutateFunction, { error }] = useMutation(loginMutationString);
  function submit() {
    setFinish(true);
    setLoading(true);
    mutateFunction()
      .then((e) => {
        while (loading) {}
        if (!error) {
          if (e.data.registerWithParams) {
            localStorage.setItem('token', e.data.registerWithParams);
            dispatch(setloggedIn(true));
            setLoading(false);
          } else {
            console.log('error', e);
            setLoading(false);
          }
        } else {
          console.log('error', e);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        // The setError should be here
        console.log('error', e);
      });
  }

  const nextStep = (stepPosition?: number) => {
    if (stepPosition) {
      setStep(stepPosition);
      return;
    }

    setStep(step + 1);
  };

  const passwordRequirementCheck: { [key: string]: boolean } = {
    hasUpCaps: /^(.*[A-Z].*)$/.test(password),
    hasLowCaps: /^(.*[a-z].*)$/.test(password),
    hasNums: /^(.*\d.*)$/.test(password),
    hasSpecial: /^(.*\W.*)$/.test(password),
    hasMinimumChar: /^.{8,32}$/.test(password),
  };

  const validPassword = (password: string): boolean => {
    return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/.test(password);
  };

  const validEmail = (email: string): boolean => {
    // eslint-disable-next-line no-control-regex
    return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email,
    );
  };

  const stepperBanner = () => {
    if (step < 1) return;
    return (
      <Paper className='registrationContainerStepper'>
        <Stepper activeStep={step - 1}>
          {['Personal Info', 'Body Data', 'Password'].map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
    );
  };

  const stepContent = () => {
    switch (step) {
      case 0:
        return (
          <RegisterStart
            email={email}
            setEmail={setEmail}
            validEmail={validEmail}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <RegisterStep1
            first={first}
            setFirst={setFirst}
            last={last}
            setLast={setLast}
            nextStep={nextStep}
          />
        );
      case 2:
        return <RegisterStep2 nextStep={nextStep} />;
      case 3:
        return (
          <RegisterStep3
            password={password}
            setPassword={setPassword}
            nextStep={nextStep}
            validPassword={validPassword}
            passwordRequirementCheck={passwordRequirementCheck}
            submit={submit}
          />
        );
      default:
        return <h1>Something went wrong. Please report this to a developer</h1>;
    }
  };

  if (loading) {
    return (
      <>
        <GeneralLoading text='Logging in...' />;
      </>
    );
  }
  if (finish) {
    return <GeneralLoading />;
  }
  return (
    <div className='registrationContainer'>
      {stepperBanner()}
      <div className='registrationContainerContent'>{stepContent()}</div>
    </div>
  );
}
