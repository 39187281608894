import { gql, DocumentNode } from '@apollo/client';
export default function DELETE_BODY_VAR_MUTATION(
  type: string | null,
  _id: string | null,
): DocumentNode {
  return gql`
  mutation deleteBodyVariable{
    deleteBodyDataVariable(type: "${type || null}", _id:"${_id}")
  }
`;
}
