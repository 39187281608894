import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfoType } from '../../types/UserInfoType';
import type { RootState } from '../store';

interface UserInfoState {
  value: UserInfoType;
}

type payloadType = {
  payload: UserInfoType;
};

const initialState: UserInfoState = {
  value: {
    first: '',
    last: '',
  },
};

export const UserInfoSlice = createSlice({
  name: 'UserInfo',
  initialState,
  reducers: {
    setUserInfo: {
      reducer: (state, action: PayloadAction<UserInfoType>) => {
        state.value = action.payload;
      },
      prepare: (UserInfo: UserInfoType): payloadType => {
        return { payload: UserInfo };
      },
    },
  },
});

export const { setUserInfo } = UserInfoSlice.actions;

export const selectUserInfo = (state: RootState) => state.userInfo.value;

export default UserInfoSlice.reducer;
