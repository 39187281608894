import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DialogForm, { bodyVariableDataObject, typingKey } from './DialogForm/DialogForm';
import './MobileDialog.scss';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface IMobileDialogProps {
  open: string | null;
  handleDialogClose: () => void;
}

export default function MobileDialog({
  open,
  handleDialogClose,
  ...props
}: IMobileDialogProps): JSX.Element {
  return (
    <Dialog
      className='mobileDialog'
      fullScreen
      {...props}
      open={open !== null}
      onClose={handleDialogClose}
      TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleDialogClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {open ? bodyVariableDataObject[open as typingKey].label : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className='mobileDialogFormWrapper'>
        <DialogForm device={'mobile'} handleDialogClose={handleDialogClose} type={open} />
      </div>
    </Dialog>
  );
}
