import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import { Typography, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { MouseEventHandler } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import './AppBarComponent.scss';
import AddNewDataMenu from '../../bodyData/AddNewDataMenu/AddNewDataMenu';
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

function getAppBar(drawer: boolean, drawerWidth?: number, open?: boolean): any {
  if (!drawer) return MuiAppBar;

  return styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
}

interface IAppBarComponentProps {
  drawer: boolean;
  drawerWidth?: number;
  handleDrawerOpen?: MouseEventHandler<HTMLButtonElement>;
  open?: boolean;
}

export default function AppBarComponent({
  drawer,
  drawerWidth,
  handleDrawerOpen,
  open,
}: IAppBarComponentProps): JSX.Element {
  const AppBar = getAppBar(drawer, drawerWidth);
  const currentPage = useAppSelector((state) => state.currentPage.value);
  return (
    <AppBar position={drawer ? 'fixed' : 'static'} open={open}>
      <Toolbar className='navBar'>
        {drawer && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant='h6' noWrap component='div'>
          {currentPage}
        </Typography>
        <Typography className='appBarAlphaVersionNumber' variant='h6'>
          v0.4.1
        </Typography>
        <div className='appBarAddButtonWrapper'>
          <AddNewDataMenu direction='down' type='desktop' />
        </div>
      </Toolbar>
    </AppBar>
  );
}
