import { useMutation } from '@apollo/client';
import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LOGIN_MUTATION from '../../../api/mutations/LOGIN_MUTATION';
import GeneralLoading from '../../../components/GeneralLoading/GeneralLoading';
import { setCurrentPagesName } from '../../../redux/slices/currentPageSlice';
import { setloggedIn } from '../../../redux/slices/loggedInSlice';
import './Login.scss';
import { Link } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPagesName('Login'));
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const loginMutationString = LOGIN_MUTATION(email, password);

  const [mutateFunction, { error }] = useMutation(loginMutationString);
  function submit() {
    setLoading(true);
    mutateFunction()
      .then((e) => {
        while (loading) {}
        if (!error) {
          if (e.data.login) {
            localStorage.setItem('token', e.data.login);
            dispatch(setloggedIn(true));
            setLoading(false);
          } else {
            console.log('error', e);
            setLoading(false);
          }
        } else {
          console.log('error', e);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log('error', e);
      });
  }
  if (loading) {
    return (
      <>
        <GeneralLoading text='Logging in...' />;
      </>
    );
  }
  return (
    <div className='login'>
      <Typography className='loginTitle' variant='h4'>
        Login
      </Typography>
      <TextField
        className='loginEmail'
        id='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label='E-mail'
        variant='outlined'
      />
      <TextField
        className='loginPassword'
        id='password'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label='Password'
        variant='outlined'
      />
      <Button variant='contained' className='loginSubmit' onClick={submit}>
        Submit
      </Button>
      <br />
      <Link to='/register'>
        <Button variant='text' className='loginSubmit'>
          Register instead
        </Button>
      </Link>
    </div>
  );
}
