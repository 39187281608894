import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { CheckCircle } from '@mui/icons-material';

interface IRegisterStep3Props {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  nextStep: (stepPosition?: number | undefined) => void;
  validPassword: (password: string) => boolean;
  passwordRequirementCheck: { [key: string]: boolean };
  submit: () => void;
}

export default function RegisterStep3({
  password,
  setPassword,
  nextStep,
  validPassword,
  passwordRequirementCheck,
  submit,
}: IRegisterStep3Props) {
  return (
    <div className='step3'>
      <Typography className='step3text' variant='h5'>
        Create a secure password
      </Typography>
      Password must have at least:
      <ul className='pwdChecklist'>
        <li className={`pwdChecklistItem${passwordRequirementCheck.hasUpCaps ? ' completed' : ''}`}>
          <CheckCircle />
          <span className='pwdChecklistItemText'>One Upper case character</span>
        </li>
        <li
          className={`pwdChecklistItem${passwordRequirementCheck.hasLowCaps ? ' completed' : ''}`}>
          <CheckCircle />
          <span className='pwdChecklistItemText'>One lower case character</span>
        </li>
        <li className={`pwdChecklistItem${passwordRequirementCheck.hasNums ? ' completed' : ''}`}>
          <CheckCircle />
          <span className='pwdChecklistItemText'>A number</span>
        </li>
        <li
          className={`pwdChecklistItem${passwordRequirementCheck.hasSpecial ? ' completed' : ''}`}>
          <CheckCircle />
          <span className='pwdChecklistItemText'>A special character</span>
        </li>
        <li
          className={`pwdChecklistItem${
            passwordRequirementCheck.hasMinimumChar ? ' completed' : ''
          }`}>
          <CheckCircle />
          <span className='pwdChecklistItemText'>8 characters</span>
        </li>
      </ul>
      <TextField
        className='registerField'
        id='password'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label='Password'
        variant='outlined'
      />
      <PasswordStrengthBar password={password} />
      <Button onClick={() => nextStep(2)}>Previous</Button>
      <Button disabled={!validPassword(password)} onClick={submit} variant='contained'>
        Finish
      </Button>
    </div>
  );
}
