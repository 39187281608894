import React, { useState, useEffect } from 'react';
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
  Divider,
  IconButton,
} from '@mui/material';
import './History.scss';
import { bodyVariableType, bodyDataKey } from '../../types/BodyDataTypes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { setCurrentPagesName } from '../../redux/slices/currentPageSlice';
import capitalizeFirstLetter from '../../utils/formatting/capitalizeFirstLetter';
import bodyTypeValueFormatter from '../../utils/BodyData/bodyTypeValueFormatter';
import dateToYMD from '../../utils/date/dateToYMD';
import GeneralLoading from '../../components/GeneralLoading/GeneralLoading';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteBodyVarDialog from './modals/DeleteBodyVarDialog';

export default function History(): JSX.Element {
  const bodyData = useAppSelector((state) => state.bodyData.value);
  const [displayedValue, setDisplayedValue] = useState<bodyDataKey>('weight');
  const [openDeleteModal, setOpenDeleteModal] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPagesName('History'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: bodyDataKey) => {
    setDisplayedValue(newValue);
  };

  if (!bodyData) return <GeneralLoading />;
  if (!displayedValue) return <GeneralLoading />;
  if (displayedValue === 'length') return <GeneralLoading />;
  if (!bodyData[displayedValue]) return <GeneralLoading />;
  return (
    <>
      <Paper>
        <Tabs value={displayedValue} onChange={handleChange} aria-label='basic tabs example'>
          {['weight', 'fat'].map((type) => (
            <Tab label={capitalizeFirstLetter(type)} value={type} />
          ))}
        </Tabs>
      </Paper>
      <Divider />
      <TableContainer id='subscriptionsTable' component={Paper}>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell>Value</TableCell>
              <TableCell className='avPricingCell' align='center'>
                Added at:
              </TableCell>
              <TableCell className='avPricingCell' align='right'>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData[displayedValue]?.map((row: bodyVariableType) => (
              <TableRow key={row._id} hover>
                <TableCell component='th' scope='row'>
                  {bodyTypeValueFormatter(Number(row.value), displayedValue)}
                </TableCell>
                <TableCell className='avPricingCell' align='center'>
                  <Chip label={dateToYMD(new Date(row.timestamp))} />
                </TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => setOpenDeleteModal(row._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteBodyVarDialog
        displayedValue={displayedValue}
        openDeleteModal={openDeleteModal}
        bodyData={bodyData}
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </>
  );
}
