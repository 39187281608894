export default function bodyTypeDisplayName(type: string) {
  switch (type) {
    case 'fat':
      return 'Fat percentage';
    case 'weight':
      return 'Weight';

    default:
      return type;
  }
}
