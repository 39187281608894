export default function bodyTypeValueFormatter(value: number, type: string) {
  switch (type) {
    case 'fat':
      return value + '%';
    case 'weight':
      return value + 'kg';

    default:
      return value;
  }
}