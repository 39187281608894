import { gql, DocumentNode } from '@apollo/client';
import { UserInfoType } from '../../types/UserInfoType';
export default function REGISTER_MUTATION(
  email: string,
  password: string,
  user: UserInfoType,
): DocumentNode {
  return gql`
  mutation registerWithParams {
    registerWithParams(email: "${email}", password: "${password}", 
                        user: { 
                          first: "${user.first}", 
                          last: "${user.last}" 
                        }
                      )
  }
  `;
}
