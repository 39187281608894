import { bodyVariableType } from '../../types/BodyDataTypes';

export default function getLatest(object: bodyVariableType[] | undefined) {
  if (!object) return;
  if (object.length === 0) return;
  if (object.length === 1) return object[0];

  return object.reduce((a, b) => {
    return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
  });
}
