import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface loggedInState {
  value: boolean | null;
}

type payloadType = {
  payload: boolean;
};

const initialState: loggedInState = {
  value: null,
};

export const loggedInSlice = createSlice({
  name: 'loggedIn',
  initialState,
  reducers: {
    setloggedIn: {
      reducer: (state, action: PayloadAction<boolean>) => {
        state.value = action.payload;
      },
      prepare: (value: boolean): payloadType => {
        return { payload: value };
      },
    },
  },
});

export const { setloggedIn } = loggedInSlice.actions;

export const selectloggedIn = (state: RootState) => state.loggedIn.value;

export default loggedInSlice.reducer;
