import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { Button, DialogContentText } from '@mui/material';
import { useMutation } from '@apollo/client';
import NEW_VAR_MUTATION from '../../../../api/mutations/NEW_VAR_MUTATION';
import './DialogForm.scss';
import useGetBodyData from '../../../hooks/useGetBodyData';
import GeneralLoading from '../../../GeneralLoading/GeneralLoading';
import { useDispatch } from 'react-redux';
import { setAppMessage } from '../../../../redux/slices/appMessageSlice';

export type typingKey = keyof typeof bodyVariableDataObject;

export const bodyVariableDataObject = {
  weight: {
    label: 'Weight',
    id: 'weight',
  },
  fat: {
    label: 'Fat Percentage',
    id: 'fat',
  },
};

interface IAfterSubmitProps {
  handleDialogClose: () => void;
}
interface IDialogFormProps {
  type: string | null;
  handleDialogClose: () => void;
  device: string;
}

function AfterSubmit({ handleDialogClose }: IAfterSubmitProps): JSX.Element {
  const bodyData = useGetBodyData();
  if (bodyData) handleDialogClose();
  return <GeneralLoading />;
}

export default function DialogForm({
  type,
  handleDialogClose,
  device,
}: IDialogFormProps): JSX.Element {
  const dispatch = useDispatch();
  const [value, setValue] = useState<number | string>('');
  const newVariableMutation = NEW_VAR_MUTATION(type, value);
  const [mutateFunction] = useMutation(newVariableMutation);
  const [submit, setSumbit] = useState<boolean>(false);
  if (!type) return <></>;
  const test = bodyVariableDataObject[type as typingKey];

  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!value) return;
    mutateFunction()
      .then((e) => {
        dispatch(setAppMessage('Succesfully added new ' + type));
        setSumbit(true);
      })
      .catch((e) => {
        alert('Something went wrong. Please report to the developer');
      });
  };
  if (submit) return <AfterSubmit handleDialogClose={() => handleDialogClose()} />;
  return (
    <>
      <DialogContent>
        <DialogContentText>
          Fill in your {bodyVariableDataObject[type as typingKey].label} of today below here to add
          it to your list
          <br />
          <br />
          Warning: You can not edit the date right now, or edit the date or value after submitting
          until future updates.
          <br />
          <br />
          After successfully submitting, the page will refresh to see the changes. Will be adjusted
          next update
        </DialogContentText>
        <br />
        <form onSubmit={(e) => submitData(e)}>
          <TextField
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
            autoFocus
            margin='dense'
            fullWidth
            variant='standard'
            inputProps={{
              step: '.1',
            }}
            type='number'
            {...test}
          />
          <br />
          <br />
          <div className='submitButtonWrapper'>
            <Button
              type='submit'
              fullWidth={device === 'mobile'}
              variant='contained'
              disabled={value ? false : true}>
              Add new {bodyVariableDataObject[type as typingKey].label}
            </Button>
          </div>
        </form>
      </DialogContent>
    </>
  );
}
