import { Card } from '@mui/material';
import React from 'react';
import './DashboardCards.scss';

interface IDashboardCardsProps {
  children: React.ReactNode;
  color: string;
}

export default function DashboardCards(props: IDashboardCardsProps): JSX.Element {
  return (
    <div className={'dashboardCardBorder ' + props.color}>
      <Card className='dashboardCard'>
        {props.children}
      </Card>
    </div>
  );
}
