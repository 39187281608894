import { Button, TextField, Typography } from '@mui/material';
import React from 'react';

interface IRegisterStep2Props {
  nextStep: (stepPosition?: number | undefined) => void;
}

export default function RegisterStep2({ nextStep }: IRegisterStep2Props) {
  return (
    <div className='step2'>
      <Typography className='step2text' variant='h5'>
        Fill in your information to continue (To come)
      </Typography>
      <TextField
        className='registerField'
        id='length'
        value=''
        // onChange={}
        label='Length'
        variant='outlined'
        disabled
      />
      <Typography variant='body1'>Optional:</Typography>
      <TextField
        className='registerField'
        id='weight'
        value=''
        // onChange={}
        label='Weight'
        variant='outlined'
        disabled
      />
      <Button onClick={() => nextStep(1)}>Previous</Button>
      <Button onClick={() => nextStep()} variant='contained'>
        Next
      </Button>
    </div>
  );
}
