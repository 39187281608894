import { gql, DocumentNode } from '@apollo/client';

const BODY_DATA_QUERY: DocumentNode = gql`
  query getUserBodyInfo {
    getUserBodyData {
      length
      weight {
        _id
        value
        timestamp
      }
      fat {
        _id
        value
        timestamp
      }
    }
  }
`;

export default BODY_DATA_QUERY;
