import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface appMessageState {
  value: string;
}

type payloadType = {
  payload: string;
};

const initialState: appMessageState = {
  value: '',
};

export const appMessageSlice = createSlice({
  name: 'appMessage',
  initialState,
  reducers: {
    setAppMessage: {
      reducer: (state, action: PayloadAction<string>) => {
        state.value = action.payload;
      },
      prepare: (message: string): payloadType => {
        return { payload: message };
      },
    },
  },
});

export const { setAppMessage } = appMessageSlice.actions;

export const selectAppMessage = (state: RootState) => state.appMessage.value;

export default appMessageSlice.reducer;
