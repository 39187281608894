import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bodyDataType } from '../../types/BodyDataTypes';
import type { RootState } from '../store';

interface bodyDataState {
  value: bodyDataType;
}

type payloadType = {
  payload: bodyDataType;
};

const initialState: bodyDataState = {
  value: '',
};

export const bodyDataSlice = createSlice({
  name: 'bodyData',
  initialState,
  reducers: {
    setbodyData: {
      reducer: (state, action: PayloadAction<bodyDataType>) => {
        state.value = action.payload;
      },
      prepare: (bodyData: bodyDataType): payloadType => {
        return { payload: bodyData };
      },
    },
  },
});

export const { setbodyData } = bodyDataSlice.actions;

export const selectbodyData = (state: RootState) => state.bodyData.value;

export default bodyDataSlice.reducer;
