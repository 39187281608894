import { gql, DocumentNode } from '@apollo/client';

const USER_INFO_QUERY: DocumentNode = gql`
  query getUserBodyInfo {
    getUserInfo {
      first
      last
      birthday
      gender
      country
    }
  }
`;

export default USER_INFO_QUERY;
