import { Button, TextField, Typography } from '@mui/material';
import React from 'react';

interface IRegisterStep1Props {
  first: string;
  setFirst: React.Dispatch<React.SetStateAction<string>>;
  last: string;
  setLast: React.Dispatch<React.SetStateAction<string>>;
  nextStep: (stepPosition?: number | undefined) => void;
}

export default function RegisterStep1({
  first,
  setFirst,
  last,
  setLast,
  nextStep,
}: IRegisterStep1Props) {
  return (
    <div className='step2'>
      <Typography className='step2text' variant='h5'>
        Fill in your information to continue
      </Typography>
      <TextField
        className='registerField'
        id='first'
        value={first}
        onChange={(e) => setFirst(e.target.value)}
        label='First name'
        variant='outlined'
      />
      <TextField
        className='registerField'
        id='last'
        value={last}
        onChange={(e) => setLast(e.target.value)}
        label='Last name'
        variant='outlined'
      />
      <Button
        onClick={() => nextStep()}
        disabled={first && last ? false : true}
        variant='contained'>
        Next
      </Button>
    </div>
  );
}
