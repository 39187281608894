import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import BODY_DATA_QUERY from '../../api/queries/BODY_DATA_QUERY';
import { useAppSelector } from '../../redux/hooks';
import { setbodyData } from '../../redux/slices/bodyDataSlice';
import { bodyDataType } from '../../types/BodyDataTypes';

// Use in redux (updates). not in components
export default function useGetBodyData(): bodyDataType | boolean {
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector((state) => state.loggedIn.value);
  const { loading, error, data } = useQuery(BODY_DATA_QUERY, {
    fetchPolicy: 'no-cache',
  });
  if (loading || error || !isLoggedIn) return false;
  dispatch(setbodyData(data.getUserBodyData));
  return data.getUserBodyData;
}
