import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { bodyVariableDataObject, typingKey } from './DialogForm/DialogForm';
import DialogTitle from '@mui/material/DialogTitle';
import DialogForm from './DialogForm/DialogForm';

interface IDesktopDialogProps {
  open: string | null;
  handleDialogClose: () => void;
}

export default function DesktopDialog({
  open,
  handleDialogClose,
  ...props
}: IDesktopDialogProps): JSX.Element {
  return (
    <Dialog {...props} open={open !== null} onClose={handleDialogClose}>
      <DialogTitle>{open ? bodyVariableDataObject[open as typingKey].label : ''}</DialogTitle>
      <DialogForm device={'desktop'} handleDialogClose={handleDialogClose} type={open} />
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
