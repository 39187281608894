import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import './MobileNavigationComponent.scss';
import AppBarComponent from '../AppBarComponent/AppBarComponent';
import { menuItemsType } from '../../../types/menuItemsType';
import { Link } from 'react-router-dom';
// import AddNewDataMenu from '../../bodyData/AddNewDataMenu/AddNewDataMenu';
import { useLocation } from 'react-router-dom';

interface IDesktopNavigationComponentProps {
  children: React.ReactNode;
  className: string;
  menuItems: menuItemsType[];
}

function chunkArray(arr: any, n: any) {
  var chunkLength = Math.max(arr.length / n, 1);
  var chunks = [];
  for (var i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
}

export default function MobileNavigationComponent(
  props: IDesktopNavigationComponentProps,
): JSX.Element {
  const { pathname } = useLocation();
  const [menuLeft, menuRight] = chunkArray(props.menuItems, 2);

  return (
    <div className={props.className}>
      <AppBarComponent drawer={false} />
      {props.children}
      <div className='MobileNavigationBarWrapper'>
        <BottomNavigation showLabels className='MobileNavigationBar'>
          {menuLeft.map((item: menuItemsType) => (
            <Link className={item.link === pathname ? 'activeItem' : ''} to={item.link || '#'}>
              <BottomNavigationAction
                onClick={item.function}
                key={item.name}
                label={item.name}
                icon={<item.icon />}
              />
            </Link>
          ))}
          {/* This is for v2 */}
          {/* <BottomNavigationAction className='mobileAddNewDataMenuWrapper' /> */}
          {/* <AddNewDataMenu type='mobile' /> */}
          {menuRight.map((item: menuItemsType) => (
            <Link className={item.link === pathname ? 'activeItem' : ''} to={item.link || '#'}>
              <BottomNavigationAction
                onClick={item.function}
                key={item.name}
                label={item.name}
                icon={<item.icon />}
              />
            </Link>
          ))}
        </BottomNavigation>
      </div>
    </div>
  );
}
