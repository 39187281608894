import React from 'react';
import './ServerOffline.scss';

export default function ServerOffline(): JSX.Element {
  return (
    <div className='serverOffline'>
      <h1>Can't make a connection to the server</h1>
      <h5 className='serverOfflineText'>Please contact our support, or try again later</h5>
      <br />
      <h4 className='serverOfflineAlphaTitle'>This application is in alpha mode:</h4>
      <h5 className='serverOfflineAlpha'>
        Please wait 30-60 seconds and refresh. <br />
        If it still doesn't work, please contact developer
      </h5>
    </div>
  );
}
