import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface currentPageState {
  value: string;
}

type payloadType = {
  payload: string;
};

const initialState: currentPageState = {
  value: '',
};

export const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setCurrentPagesName: {
      reducer: (state, action: PayloadAction<string>) => {
        state.value = action.payload;
      },
      prepare: (text: string): payloadType => {
        return { payload: text };
      },
    },
  },
});

export const { setCurrentPagesName } = currentPageSlice.actions;

export const selectCurrentPage = (state: RootState) => state.currentPage.value;

export default currentPageSlice.reducer;
