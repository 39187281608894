import { CircularProgress } from '@mui/material';
import React from 'react';
import './GeneralLoading.scss';

interface IGeneralLoadingProps {
  text?: string;
}

export default function GeneralLoading({ text }: IGeneralLoadingProps): JSX.Element {
  return (
    <div className='generalLoading'>
      {text && <p className='generalLoadingTitle'>{text}</p>}
      <CircularProgress className='generalLoadingProgress' size='4rem' />
      <p className='generalLoadingText'>LOADING</p>
    </div>
  );
}
