import { useMutation } from '@apollo/client';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import DELETE_BODY_VAR_MUTATION from '../../../api/mutations/DELETE_BODY_VAR_MUTATION';
import GeneralLoading from '../../../components/GeneralLoading/GeneralLoading';
import useGetBodyData from '../../../components/hooks/useGetBodyData';
import { bodyDataType, bodyVariableType } from '../../../types/BodyDataTypes';
import bodyTypeDisplayName from '../../../utils/BodyData/bodyTypeDisplayName';
import bodyTypeValueFormatter from '../../../utils/BodyData/bodyTypeValueFormatter';
import dateToYMD from '../../../utils/date/dateToYMD';

interface IAfterSubmitProps {
  handleDialogClose: () => void;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}

function AfterSubmit({ handleDialogClose, setSubmit }: IAfterSubmitProps): JSX.Element {
  const bodyData = useGetBodyData();
  if (bodyData) {
    handleDialogClose();
    setSubmit(false);
  }
  return <GeneralLoading />;
}

interface IDeleteBodyVarDialogProps {
  displayedValue: string;
  openDeleteModal: string | null;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<string | null>>;
  bodyData: bodyDataType;
}

export default function DeleteBodyVarDialog({
  displayedValue,
  openDeleteModal,
  setOpenDeleteModal,
  bodyData,
}: IDeleteBodyVarDialogProps): JSX.Element {
  const loginMutationString = DELETE_BODY_VAR_MUTATION(displayedValue, openDeleteModal);
  const [submit, setSubmit] = useState<boolean>(false);

  const [mutateFunction, { error }] = useMutation(loginMutationString);
  if (submit)
    return <AfterSubmit setSubmit={setSubmit} handleDialogClose={() => setOpenDeleteModal(null)} />;

  function deleteBodyVarSubmit() {
    if (!displayedValue || !openDeleteModal) return;

    mutateFunction()
      .then((e: any) => {
        if (!error) {
          if (e.data.deleteBodyDataVariable === 'success') {
            setSubmit(true);
          } else {
            alert('Something went wrong. Please report to developer');
            console.error('error', e);
          }
        } else {
          alert('Something went wrong. Please report to developer');
          console.error('error', e);
        }
      })
      .catch((e) => {
        alert('Something went wrong. Please report to developer');
        console.error('error', e);
      });
  }
  return (
    <Dialog open={openDeleteModal !== null} onClose={() => setOpenDeleteModal(null)}>
      <DialogTitle id='alert-dialog-title'>{"Use Google's location service?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Would you like to delete this {bodyTypeDisplayName(displayedValue)} entry?
          <br />
          <Chip
            label={bodyTypeValueFormatter(
              Number(
                bodyData[displayedValue as 'weight' | 'fat']?.find(
                  (value: bodyVariableType) => value._id === openDeleteModal,
                )?.value || 0,
              ),
              displayedValue,
            )}
          />
          <Chip
            label={dateToYMD(
              new Date(
                bodyData[displayedValue as 'weight' | 'fat']?.find(
                  (value: bodyVariableType) => value._id === openDeleteModal,
                )?.timestamp || 0,
              ),
            )}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDeleteModal(null)}>Disagree</Button>
        <Button onClick={() => deleteBodyVarSubmit()} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
