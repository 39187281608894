import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import USER_INFO_QUERY from '../../api/queries/USER_INFO_QUERY';
import { useAppSelector } from '../../redux/hooks';
import { setUserInfo } from '../../redux/slices/userInfoSlice';
import { bodyDataType } from '../../types/BodyDataTypes';

// Use in redux (updates). not in components
export default function useGetUserInfo(): bodyDataType | boolean {
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector((state) => state.loggedIn.value);
  const { loading, error, data } = useQuery(USER_INFO_QUERY, {
    fetchPolicy: 'no-cache',
  });
  if (loading || error || !isLoggedIn) return false;
  dispatch(setUserInfo(data.getUserInfo));
  return data.getUserInfo;
}
