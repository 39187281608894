import React from 'react';
import DesktopNavigationComponent from './DesktopNavigationComponent/DesktopNavigationComponent';
import MobileNavigationComponent from './MobileNavigationComponent/MobileNavigationComponent';
import RestoreIcon from '@mui/icons-material/Restore';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import './NavigationWrapperComponent.scss';
import { useAppSelector } from '../../redux/hooks';
import logout from '../../utils/auth/logout';
import { menuItemsType } from '../../types/menuItemsType';
import useMediaQuery from '@mui/material/useMediaQuery';

interface INavigationWrapperComponentProps {
  children: React.ReactNode;
}

const menuItems: menuItemsType[] = [
  {
    name: 'Dashboard',
    icon: HomeIcon,
    link: '/',
  },
  {
    name: 'History',
    icon: RestoreIcon,
    link: '/history',
  },
  {
    name: 'Settings',
    icon: SettingsIcon,
    function: () => {
      console.log('hello');
    },
  },
  {
    name: 'Log out',
    icon: LogoutIcon,
    divider: true,
    function: () => logout(),
  },
];

export default function NavigationWrapperComponent(
  props: INavigationWrapperComponentProps,
): JSX.Element {
  const matches = useMediaQuery('(max-width:900px)');
  const currentPage = useAppSelector((state) => state.currentPage.value);
  if (currentPage.toLowerCase() === 'login') {
    return <>{props.children}</>;
  }
  return matches ? (
    <MobileNavigationComponent menuItems={menuItems} className='desktopNavigationComponent'>
      {props.children}
    </MobileNavigationComponent>
  ) : (
    <DesktopNavigationComponent menuItems={menuItems} className='mobileNavigationComponent'>
      {props.children}
    </DesktopNavigationComponent>
  );
}
