import { Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface IRegisterStartProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  validEmail: (email: string) => boolean;
  nextStep: (stepPosition?: number | undefined) => void;
}

export default function RegisterStart({
  email,
  setEmail,
  validEmail,
  nextStep,
}: IRegisterStartProps) {
  return (
    <div className='step1'>
      <Typography className='registerTitle' variant='h4'>
        Create your Oga Fitness account!
      </Typography>
      <Typography className='registerTitle' variant='h5'>
        Fill in your email to start
      </Typography>
      <TextField
        className='registerField'
        id='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label='E-mail'
        variant='outlined'
      />
      <Typography className='registerTos' variant='body1'>
        By creating a new account, you agree to the Oga Fitness terms and conditions, privacy
        policy. And the Oga Fitness body tracker Terms and Conditions
      </Typography>
      <Button
        disabled={!validEmail(email)}
        className='registerSubmit'
        variant='contained'
        onClick={
          validEmail(email)
            ? () => nextStep()
            : () => console.error('Please dont manually remove the disable')
        }>
        Submit
      </Button>
      <br />
      <Link to='/login'>
        <Button variant='text' className='loginSubmit'>
          Already have an account? Log in
        </Button>
      </Link>
    </div>
  );
}
