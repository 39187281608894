import { configureStore } from '@reduxjs/toolkit';
import appMessageSlice from './slices/appMessageSlice';
import bodyDataSlice from './slices/bodyDataSlice';
import currentPageSlice from './slices/currentPageSlice';
import loggedInSlice from './slices/loggedInSlice';
import userInfoSlice from './slices/userInfoSlice';
// ...

const store = configureStore({
  reducer: {
    currentPage: currentPageSlice,
    userInfo: userInfoSlice,
    loggedIn: loggedInSlice,
    bodyData: bodyDataSlice,
    appMessage: appMessageSlice,
  },
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
