import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { setAppMessage } from '../../redux/slices/appMessageSlice';

export default function AppMessage() {
  const dispatch = useDispatch();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setAppMessage(''));
  };

  const action = (
    <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  const open = useAppSelector((state) => state.appMessage.value);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={Boolean(open)}
      autoHideDuration={2000}
      onClose={handleClose}
      message={open}
      action={action}
    />
  );
}
