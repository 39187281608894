import React from 'react';
import AppMessage from './components/AppMessage/AppMessage';
import { Routes, Route, Navigate } from 'react-router-dom';
import useGetBodyData from './components/hooks/useGetBodyData';
import useGetUserInfo from './components/hooks/useGetUserInfo';
import Login from './pages/Auth/Login/Login';
import Register from './pages/Auth/Register/Register';
import Dashboard from './pages/Dashboard';
import History from './pages/History/History';
import { useAppSelector } from './redux/hooks';

export default function RoutingList(): JSX.Element {
  const loggedIn = useAppSelector((state) => state.loggedIn.value);
  useGetBodyData();
  useGetUserInfo();
  function RoutingList() {
    if (!loggedIn) {
      return (
        <>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/login' replace />} />
        </>
      );
    }
    return (
      <>
        <Route path='/' element={<Dashboard />} />
        <Route path='/history' element={<History />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </>
    );
  }

return (
    <>
      <Routes>{RoutingList()}</Routes>
      <AppMessage />
    </>
  );
}
