/* eslint-disable react-hooks/exhaustive-deps */
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import NavigationWrapperComponent from './components/nav/NavigationWrapperComponent';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useMutation,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import { setloggedIn } from './redux/slices/loggedInSlice';
import usePing from './components/hooks/usePing';
import AUTH_MUTATION from './api/mutations/AUTH_MUTATION';
import GeneralLoading from './components/GeneralLoading/GeneralLoading';
import ServerOffline from './components/ServerOffline/ServerOffline';
import { BrowserRouter } from 'react-router-dom';
import { useAppSelector } from './redux/hooks';
import './global.scss'

const link = createHttpLink({
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4000/graphql'
      : 'https://api.ogafit.com/graphql',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem('token'),
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [appLoading, setAppLoading] = useState(true);
  const [serverOnline, setServerOnline] = useState<boolean | null>(null);
  const [mutateFunction, { /* loading ,*/ error }] = useMutation(AUTH_MUTATION);
  const dispatch = useDispatch();
  const ping = usePing();
  const loggedIn = useAppSelector((state) => state.loggedIn.value);

  useEffect(() => {
    if (ping[0]) return;
    if (!ping[1]) {
      setServerOnline(false);
      return;
    } else {
      setServerOnline(true);
    }
  }, [ping]);

  useEffect(() => {
    if (localStorage.getItem('token') && appLoading) {
      mutateFunction()
        .then((e) => {
          // This also used to be necessary. Keeping it temporarily for future reference
          // while (loading) {}
          if (!error) {
            if (e.data) {
              dispatch(setloggedIn(true));
              setAppLoading(false);
            }
          } else {
            setAppLoading(false);
            localStorage.clear();
          }
        })
        .catch((e) => {
          setAppLoading(false);
          localStorage.clear();
        });
    } else if (!localStorage.getItem('token') && appLoading) {
      setAppLoading(false);
    }
  }, []);
  if (serverOnline === false) {
    return <ServerOffline />;
  }
  if (appLoading || serverOnline === null) {
    return <GeneralLoading text='Body Tracker' />;
  }
  // Im temporarily keeping this code to try and find out what the point was of "loggedIn" check
  // if (localStorage.getItem('token') && appLoading && loggedIn === null) {
  //   return <CircularProgress />;
  // }
  return (
    <BrowserRouter>
      {loggedIn ? (
        <NavigationWrapperComponent>
          <Routes />
        </NavigationWrapperComponent>
      ) : (
        <Routes />
      )}
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
