import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedIcon from '@mui/icons-material/Speed';
import AssessmentIcon from '@mui/icons-material/Assessment';
import './AddNewDataMenu.scss';
import MobileDialog from '../AddNewDataModal/MobileDialog';
import DesktopDialog from '../AddNewDataModal/DesktopDialog';

const actions = [
  { icon: <SpeedIcon />, name: 'Add new Weight', type: 'weight' },
  { icon: <AssessmentIcon />, name: 'Add new Fat Percentage', type: 'fat' },
];

interface IAddNewDataMenuProps {
  direction?: 'up' | 'down';
  type: 'desktop' | 'mobile';
}

export default function AddNewDataMenu({ direction = 'up', type }: IAddNewDataMenuProps) {
  //menu
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dialogOpen, setDialogOpen] = useState<string | null>(null);

  const handleDialogClickOpen = (type: string) => {
    setDialogOpen(type);
  };
  const handleDialogClose = () => {
    setDialogOpen(null);
    handleClose();
  };
  const dataDialog = () => {
    if (type === 'mobile')
      return <MobileDialog open={dialogOpen} handleDialogClose={handleDialogClose} />;
    return <DesktopDialog open={dialogOpen} handleDialogClose={handleDialogClose} />;
  };
  return (
    <>
      {dataDialog()}
      <Backdrop className='backdropTest' open={open} />
      <Box className='speedDialWrapper'>
        <SpeedDial
          className={'speedDial ' + type}
          ariaLabel='SpeedDial tooltip'
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onClick={handleOpen}
          direction={direction}
          open={open}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => handleDialogClickOpen(action.type)}
            />
          ))}
        </SpeedDial>
      </Box>
    </>
  );
}
